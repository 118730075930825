import React from 'react'

const ServiceCard = ({ icon, title, description }) => {
  return (
    <div className='flex flex-col items-center h-64 gap-4 p-8 bg-gray-100 rounded-lg'>
      <div>
        <img className='h-14' src={icon} alt={title} />
      </div>
      <div>
        <h3 className='text-xl font-bold uppercase text-primary'>{title}</h3>
      </div>
      <div>
        { description }
      </div>
    </div>
  )
}

export default ServiceCard