import React from 'react'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import ReactMarkdown from 'react-markdown'

import FancyImage from '../components/FancyImage'
import GradientContainer from '../components/GradientContainer'
import Hero from '../components/Hero'
import NavigationBar from '../components/NavigationBar'
import SectionHeader from '../components/SectionHeader'
import Paragraph from '../components/Paragraph'
import ServiceCard from '../components/ServiceCard'
import Footer from '../components/Footer'
import LetsWorkTogether from '../components/LetsWorkTogether'
import { SEO } from '../components/SEO'

import cloud from '../images/cloud.svg'
import computer from '../images/computer.svg'
import gear from '../images/gear.svg'
import arrow from '../images/arrow.svg'

const services = ({ data }) => {
  const d = data.servicesYaml

  const servicesAnim = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delay: 0.1,
      },
    },
  }

  const serviceItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  }

  return (
    <div>
      <GradientContainer>
        <div className='container mx-auto'>
          <div className='md:mb-24'>
            <NavigationBar />
            <Hero
              title={d.heading}
              description={<ServiceDescription desc={d.subheading} />}
              featured={
                <FancyImage
                  image={d.header_image}
                  classes='bg-dark'
                  x={20}
                  y={20}
                />
              }
            />
          </div>
        </div>
      </GradientContainer>
      <main>
        <div className='container p-4 mx-auto'>
          <div className='flex flex-col justify-around gap-8 -translate-y-20 md:flex-row'>
            <a href='#itservices'>
              <div className='flex flex-col w-full gap-6 p-8 bg-white rounded-lg shadow-lg md:w-64 lg:w-80'>
                <div className='flex items-center justify-center'>
                  <img className='h-10' src={cloud} alt='cloud' />
                </div>
                <div>
                  <div className='flex items-center justify-center gap-6'>
                    <div className='text-2xl font-bold text-dark'>
                      Information <br /> Technology
                    </div>
                    <div>
                      <img src={arrow} alt='arrow' className='h-4' />
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <a href='#engservices'>
              <div className='flex flex-col w-full gap-6 p-8 bg-white rounded-lg shadow-lg md:w-64 lg:w-80'>
                <div className='flex items-center justify-center'>
                  <img className='h-10' src={computer} alt='computer' />
                </div>
                <div>
                  <div className='flex items-center justify-center gap-6'>
                    <div className='text-2xl font-bold text-dark'>
                      Engineering <br /> Services
                    </div>
                    <div>
                      <img src={arrow} alt='arrow' className='h-4' />
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <a href='#cloudmigration'>
              <div className='flex flex-col w-full gap-6 p-8 bg-white rounded-lg shadow-lg md:w-64 lg:w-80'>
                <div className='flex items-center justify-center'>
                  <img className='h-10' src={gear} alt='gear' />
                </div>
                <div>
                  <div className='flex items-center justify-center gap-6'>
                    <div className='text-2xl font-bold text-dark'>
                      VMWare Cloud <br /> Migration
                    </div>
                    <div>
                      <img src={arrow} alt='arrow' className='h-4' />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <SectionHeader noPadding={true}>Core Competencies</SectionHeader>
          <div className='flex flex-col justify-between gap-2 md:gap-16 lg:flex-row'>
            <div className='flex-1'>
              <Paragraph>
                <ReactMarkdown>{d.core_competencies}</ReactMarkdown>
              </Paragraph>
            </div>
            <div className='w-full md:w-1/3'>
              <FancyImage
                image={d.core_competencies_image}
                classes='bg-gray-100'
                x={20}
                y={20}
              />
            </div>
          </div>

          <div id='itservices'>
            <SectionHeader>Information Technology Services</SectionHeader>
          </div>
          <motion.div
            className='grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-12'
            variants={servicesAnim}
            initial='hidden'
            whileInView='show'
            viewport={{ once: true }}
          >
            {d.information_technology_services.map((service, index) => (
              <motion.div
                key={index}
                variants={serviceItem}
                viewport={{ once: true }}
              >
                <ServiceCard
                  icon={service.icon}
                  title={service.name}
                  description={service.description}
                />
              </motion.div>
            ))}
          </motion.div>

          <div id='engservices'>
            <SectionHeader>Engineering Services</SectionHeader>
          </div>
          <motion.div
            className='grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-12'
            variants={servicesAnim}
            initial='hidden'
            whileInView='show'
            viewport={{ once: true }}
          >
            {d.engineering_services.map((service, index) => (
              <motion.div
                key={index}
                variants={serviceItem}
                viewport={{ once: true }}
              >
                <ServiceCard
                  icon={service.icon}
                  title={service.name}
                  description={service.description}
                />
              </motion.div>
            ))}
          </motion.div>

          <div id='cloudmigration'>
            <SectionHeader>VMWare Cloud Migration</SectionHeader>
          </div>
          <motion.div
            className='grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-12'
            variants={servicesAnim}
            initial='hidden'
            whileInView='show'
            viewport={{ once: true }}
          >
            {d.vmware_cloud_migration.map((service, index) => (
              <motion.div
                key={index}
                variants={serviceItem}
                viewport={{ once: true }}
              >
                <ServiceCard
                  icon={service.icon}
                  title={service.name}
                  description={service.description}
                />
              </motion.div>
            ))}
          </motion.div>
          <LetsWorkTogether />
        </div>
      </main>
      <Footer />
    </div>
  )
}

const ServiceDescription = ({ desc }) => {
  return (
    <>
      <p className='mb-8 text-lg md:text-2xl'>{desc}</p>
    </>
  )
}

export default services

export const Head = () => <SEO title='Viranim - Services' />

export const query = graphql`
  query ServicesQuery {
    servicesYaml {
      id
      information_technology_services {
        description
        icon
        name
      }
      engineering_services {
        description
        icon
        name
      }
      vmware_cloud_migration {
        description
        icon
        name
      }
      heading
      core_competencies
      subheading
      core_competencies_image
      header_image
    }
  }
`
